export const defaultMeta = [
    {
        vmid: 'description',
        name: 'description',
        content: '무제한으로 원하는 모습을 찍고 꾸미세요! 무료 폴라로이드 촬영 페이지, Pick The Moment'
    },
    {
        vmid: 'keywords',
        name: 'keywords',
        content: '폴라로이드, 스티커사진',
    },
    {
        vmid: 'author',
        name: 'author',
        content: 'dnfwlxo11',
    }
]